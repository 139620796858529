<script>
  import { onMount } from 'svelte';
  import { inertia } from '@inertiajs/svelte';
  let footer_tw = 'flex justify-between items-end mt-11 pt-1 mx-3 border-t-2 border-gray-300 text-gray-500';
  export let mobile_layout = false;

</script>

<footer class="footer-container {footer_tw}">
  {#if mobile_layout}
    <div class="flex flex-col gap-y-2">
      <small>
        The <a href="https://www.railstutorial.org/">Ruby on Rails Tutorial</a>
        by <a href="https://www.michaelhartl.com/">Michael Hartl</a>
      </small>
      <nav>
        <ul class="flex flex-col">
          <li class="mr-6">
            <a href="/about" use:inertia>About</a>
          </li>
          <li class="mr-6">
            <a href="/contact" use:inertia>Contact</a>
          </li>
          <li class="mr-6">
            <a href="https://news.railstutorial.org/">News</a>
          </li>
        </ul>
      </nav>
    </div>
  {:else}
    <small>
      The <a href="https://www.railstutorial.org/">Ruby on Rails Tutorial</a>
      by <a href="https://www.michaelhartl.com/">Michael Hartl</a>
    </small>
    <nav>
      <ul class="flex">
        <li class="mr-6">
          <a href="/about" use:inertia>About</a>
        </li>
        <li class="mr-6">
          <a href="/contact" use:inertia>Contact</a>
        </li>
        <li class="mr-6">
          <a href="https://news.railstutorial.org/">News</a>
        </li>
      </ul>
    </nav>
  {/if}
</footer>
