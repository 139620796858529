<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import { inertia, Link } from '@inertiajs/svelte';
  import PageTitle from '@/components/page_title.svelte';
  import Form from "./_form.svelte";
  import { page } from "@inertiajs/svelte";
  let user_name = $page.props.user.name;
  let gravatar_src = $page.props.gravatar_src;

  // console.log("view: ", $page.component);
  // console.log("page: ", $page);
</script>

<PageTitle>Update your profile</PageTitle>

<div class="form-container w-full max-w-xs m-auto">
  <Form/>
  <div class="gravatar_edit flex px-4 my-3">
      <div class="gravatar_img_box px-3">
        <img class="gravatar" alt={user_name} src={gravatar_src}>
      </div>
      <div class="gravatar_edit_link_box">
        <a href="https://gravatar.com/emails" target="_blank">change</a>
      </div>
  </div>
  <p class="text-center text-gray-500 text-xs">
    &copy;2023 Lentobox All rights reserved.
  </p>
</div>
