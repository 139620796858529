<script>
  import { createEventDispatcher } from 'svelte'; 
  const dispatch = createEventDispatcher();
  export let editor;

  const editor_toolbar_btn = 'px-2 py-1 rounded-md focus:outline-none';
  const btn_default_bg = 'bg-gray-200 hover:bg-gray-300';
  const btn_active_bg = 'bg-black'

  function prevent_default(e){
      e.preventDefault();
  }
</script>

<div class="toolbar mb-2">
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive('bold') ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_bold')}
  >
  <!-- on:mousedown={prevent_default} -->
    <!-- Bold -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive('bold')}
    >
      <path
        d="M272-200v-560h221q65 0 120 40t55 111q0 51-23 78.5T602-491q25 11 55.5 41t30.5 90q0 89-65 124.5T501-200H272Zm121-112h104q48 0 58.5-24.5T566-372q0-11-10.5-35.5T494-432H393v120Zm0-228h93q33 0 48-17t15-38q0-24-17-39t-44-15h-95v109Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive('italic') ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_italic')}
  >
  <!-- on:mousedown={prevent_default} -->
    <!-- Italic -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive('italic')}
    >
        <path
          d="M200-200v-100h160l120-360H320v-100h400v100H580L460-300h140v100H200Z"
        >
    <svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive('bulletList') ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_list', { type: 'bullet_list' })}
  >
  <!-- on:mousedown={prevent_default} -->
    <!-- Bullet List -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive('bulletList')}
    >
      <path
        d="M360-200v-80h480v80H360Zm0-240v-80h480v80H360Zm0-240v-80h480v80H360ZM200-160q-33 0-56.5-23.5T120-240q0-33 23.5-56.5T200-320q33 0 56.5 23.5T280-240q0 33-23.5 56.5T200-160Zm0-240q-33 0-56.5-23.5T120-480q0-33 23.5-56.5T200-560q33 0 56.5 23.5T280-480q0 33-23.5 56.5T200-400Zm0-240q-33 0-56.5-23.5T120-720q0-33 23.5-56.5T200-800q33 0 56.5 23.5T280-720q0 33-23.5 56.5T200-640Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive('orderedList') ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_list', { type: 'ordered_list' })}
  >
    <!-- on:mousedown={prevent_default} -->
    <!-- Numbered List -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive('orderedList')}
    >
      <path
        d="M120-80v-60h100v-30h-60v-60h60v-30H120v-60h120q17 0 28.5 11.5T280-280v40q0 17-11.5 28.5T240-200q17 0 28.5 11.5T280-160v40q0 17-11.5 28.5T240-80H120Zm0-280v-110q0-17 11.5-28.5T160-510h60v-30H120v-60h120q17 0 28.5 11.5T280-560v70q0 17-11.5 28.5T240-450h-60v30h100v60H120Zm60-280v-180h-60v-60h120v240h-60Zm180 440v-80h480v80H360Zm0-240v-80h480v80H360Zm0-240v-80h480v80H360Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive("heading", { level: 1 }) ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_heading', { level: 1 })}
    on:mousedown={prevent_default}
    class:invert={editor.isActive("heading", { level: 1 })}
  >
    <!-- Heading 1 -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M200-280v-400h80v160h160v-160h80v400h-80v-160H280v160h-80Zm480 0v-320h-80v-80h160v400h-80Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive("heading", { level: 2 }) ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_heading', { level: 2 })}
    on:mousedown={prevent_default}
  >
    <!-- Heading 2 -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive("heading", { level: 2 })}
    >
      <path
        d="M120-280v-400h80v160h160v-160h80v400h-80v-160H200v160h-80Zm400 0v-160q0-33 23.5-56.5T600-520h160v-80H520v-80h240q33 0 56.5 23.5T840-600v80q0 33-23.5 56.5T760-440H600v80h240v80H520Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive("heading", { level: 3 }) ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_heading', { level: 3 })}
    on:mousedown={prevent_default}
  >
    <!-- Heading 3 -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive("heading", { level: 3 })}
    >
      <path
        d="M120-280v-400h80v160h160v-160h80v400h-80v-160H200v160h-80Zm400 0v-80h240v-80H600v-80h160v-80H520v-80h240q33 0 56.5 23.5T840-600v240q0 33-23.5 56.5T760-280H520Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive('blockquote') ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_blockquote')}
    on:mousedown={prevent_default}
  >
    <!-- Quote -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive('blockquote')}
    >
      <path
        d="m228-240 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T458-480L320-240h-92Zm360 0 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T818-480L680-240h-92ZM320-500q25 0 42.5-17.5T380-560q0-25-17.5-42.5T320-620q-25 0-42.5 17.5T260-560q0 25 17.5 42.5T320-500Zm360 0q25 0 42.5-17.5T740-560q0-25-17.5-42.5T680-620q-25 0-42.5 17.5T620-560q0 25 17.5 42.5T680-500Zm0-60Zm-360 0Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive('code') ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_code')}
    on:mousedown={prevent_default}
  >
    <!-- Code -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive('code')}
    >
      <path
        d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} { editor.isActive('codeBlock') ? `is-active ${btn_active_bg}` : btn_default_bg }"
    on:click={() => dispatch('toggle_codeblock')}
    on:mousedown={prevent_default}
  >
    <!-- Code Block -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      class:invert={editor.isActive('codeBlock')}
    >
      <path
        d="m384-336 56-57-87-87 87-87-56-57-144 144 144 144Zm192 0 144-144-144-144-56 57 87 87-87 87 56 57ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} {btn_default_bg}"
    on:click={() => dispatch('set_link')}
    on:mousedown={prevent_default}
  >
    <!-- Link -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z"
      />
    </svg>
  </button>
  <button
    type="button"
    class="mr-2 {editor_toolbar_btn} {btn_default_bg}"
    on:click={() => dispatch('set_image')}
    on:mousedown={prevent_default}
  >
    <!-- Image -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z"
      />
    </svg>
  </button>
  <!-- <button
    type="button"
    class="{editor_toolbar_btn} {btn_default_bg}"
    on:click={() => dispatch('add_table')}
    on:mousedown={prevent_default}
  > -->
    <!-- Table -->
    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200Zm80-400h560v-160H200v160Zm213 200h134v-120H413v120Zm0 200h134v-120H413v120ZM200-400h133v-120H200v120Zm427 0h133v-120H627v120ZM200-200h133v-120H200v120Zm427 0h133v-120H627v120Z"
      />
    </svg>
  </button> -->
  <!-- <button
    type="button"
    class="{editor_toolbar_btn} {btn_default_bg}"
    on:click={() => dispatch('attach_file')}
    on:mousedown={prevent_default}
  > -->
    <!-- Attach File -->
    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M720-330q0 104-73 177T470-80q-104 0-177-73t-73-177v-370q0-75 52.5-127.5T400-880q75 0 127.5 52.5T580-700v350q0 46-32 78t-78 32q-46 0-78-32t-32-78v-370h80v370q0 13 8.5 21.5T470-320q13 0 21.5-8.5T500-350v-350q-1-42-29.5-71T400-800q-42 0-71 29t-29 71v370q-1 71 49 120.5T470-160q70 0 119-49.5T640-330v-390h80v390Z"
      />
    </svg>
  </button> -->
  <!-- <button
    type="button"
    class="{editor_toolbar_btn} {btn_default_bg}"
    on:click={() => dispatch('share')}
    on:mousedown={prevent_default}
  > -->
    <!-- Share -->
    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z"
      />
    </svg>
  </button> -->
  <!-- <button
    type="button"
    class="{editor_toolbar_btn} {btn_default_bg}"
    on:click={() => dispatch('mention')}
    on:mousedown={prevent_default}
  > -->
    <!-- Mention `@` -->
    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v58q0 59-40.5 100.5T740-280q-35 0-66-15t-52-43q-29 29-65.5 43.5T480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480v58q0 26 17 44t43 18q26 0 43-18t17-44v-58q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h200v80H480Zm0-280q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"
      />
    </svg>
  </button> -->
</div>


<!-- Markdown -->
<!-- <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m640-360 120-120-42-43-48 48v-125h-60v125l-48-48-42 43 120 120ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Zm60-120h60v-180h40v120h60v-120h40v180h60v-200q0-17-11.5-28.5T440-600H260q-17 0-28.5 11.5T220-560v200Z"/></svg> -->

