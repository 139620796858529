import { TextSelection } from "prosemirror-state";

function is_valid_image_file( file ){
    const valid_types = [ 'image/jpeg', 'image/png' ];
    const max_size = 10 * 1024 * 1024; // 10 MB
    return valid_types.includes( file.type ) && file.size < max_size;
}

function is_valid_image_dimensions( width, height ){
    const max_dimension = 5000;
    return width <= max_dimension && height <= max_dimension;
}

function handle_image_load(){
    console.log('loaded');
    if(!is_valid_image_dimensions( img.width, img.height )){
        window.alert("Your images need to be less than 5000 pixels in height and width.");
        return;
    }
}

function load_image( file ){
    let _URL = window.URL || window.webkitURL;
    let img = new window.Image();
    img.file = file;
    img.onload = handle_image_load;
    img.src = _URL.createObjectURL( file );
}

function handle_rte_image_append( img, view, event ){
    // console.log("loaded");
    if(!is_valid_image_dimensions( img.width, img.height )){
        window.alert("Your images need to be less than 5000 pixels in height and width.");
        return;
    }

    // valid image, so append to editor
    const { schema } = view.state;
    const coordinates = view.posAtCoords( { left: event.clientX, top: event.clientY } );
    const node = schema.nodes.image.create({ src: img.src }); // creates the image element
    const transaction = view.state.tr.insert( coordinates.pos, node ); // places it in the correct position
    return view.dispatch( transaction );
}

function load_rte_image( file, view, event ){
    let _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.file = file;
    img.onload = function(){
        handle_rte_image_append( this, view, event );
    }
    img.src = _URL.createObjectURL( file );
}

async function drop_insert_rte_image_view( file, view, event ){
    const { schema } = view.state;
    const coordinates = view.posAtCoords( { left: event.clientX, top: event.clientY } );
    const image_node = await create_image_node( file, schema );
    const paragraph_node = schema.nodes.paragraph.create();
    insert_image_node( image_node, paragraph_node, view, coordinates );

}

async function insert_rte_image_view( file, view ){
    const { schema } = view.state;
    const image_node = await create_image_node( file, schema );
    const paragraph_node = schema.nodes.paragraph.create();
    insert_image_node( image_node, paragraph_node, view );
}

function create_image_node( file, schema ){
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
            const attrs = {
                src: reader.result,
                alt: file.name,
                allowCaption: true,
            };
            const image_node = schema.nodes.imageNode.create( attrs );
            resolve( image_node );
        };
        reader.readAsDataURL( file );
    });
}

async function insert_image_node( image_node, paragraph_node, view, coordinates ){
    const { state, dispatch } = view;
    const { tr } = state;

    let transaction;
    if(coordinates){
        transaction = tr.insert( coordinates.pos, [ image_node, paragraph_node ] );
    } else {
        transaction = tr.replaceSelectionWith( image_node ).insert( state.selection.to, paragraph_node );
    }
    dispatch( transaction );

    let cursor_pos;
    if(coordinates){
        cursor_pos = coordinates.pos + image_node.nodeSize + 1;
    } else {
        cursor_pos = state.selection.to + 1;
    }
    const transaction2 = view.state.tr.setSelection( TextSelection.create( view.state.doc, cursor_pos ) );
    dispatch( transaction2 );

    view.focus();
}

export {
    is_valid_image_file,
    is_valid_image_dimensions,
    load_rte_image,
    insert_rte_image_view,
    drop_insert_rte_image_view,
    load_image,
}
