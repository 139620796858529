<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import { page, useForm } from "@inertiajs/svelte";
  import PageTitle from '@/components/page_title.svelte';
  export let flash;
  export let email_not_found = false;
  let tw_label_styles = "block text-gray-700 text-sm font-bold mb-2";
  let tw_input_styles = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
  let bluebtn = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
  let errortext = "text-red-500 text-xs italic";

  console.log("$page: ", $page);
  console.log("flash: ", flash);

  let form = useForm({
      email: null,
  });

  console.log("$form: ", $form);

  function submit(){
      $form.post('/password_resets', {
          onSuccess: () => {
              if(email_not_found){
                  $form.reset();
              }
          }
      });
  }
</script>

<PageTitle>Reset your password</PageTitle>
<div class="form-container w-full max-w-xs m-auto">
  <form on:submit|preventDefault={submit} class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <div class="reset-instructions mb-4">
      <p class="text-center">Enter your email address and we will send you instructions to reset your password.</p>
    </div>
    <div class="form-input-box mb-4">
      <label for="email" class={tw_label_styles}>Email</label>
      <input
        type="email"
        name="email"
        id="email"
        class={`${tw_input_styles} ${ $form.errors.email ? 'border-red-500' : ''}`}
        bind:value={$form.email}
      >
    </div>
    <div class="flex items-center justify-between my-4">
      <button type="submit" class="{bluebtn} w-full" disabled={$form.processing}>Submit</button>
    </div>
  </form>
  <p class="text-center text-gray-500 text-xs">
    &copy;2023 Lentobox All rights reserved.
  </p>
</div>
