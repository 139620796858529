<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import PageTitle from '@/components/page_title.svelte';
</script>

<PageTitle>Contact</PageTitle>

<p>
  Contact the Ruby on Rails Tutorial about the sample app at the
  <a href="https://www.railstutorial.org/contact">contact page</a>.
</p>
