<script>
  import { page, useForm } from "@inertiajs/svelte";
  import InputPassword from "@/components/input_password.svelte";
  export let flash;

  console.log("page: ", $page);
  console.log("flash: ", flash);

  let page_role = $page.component.split('/')[1];

  let form;
  let has_name_error = false;
  let has_email_error = false;
  let has_password_error = false;
  let has_password_confirmation_error = false;

  function set_form(){
      // console.log("page_role: ", page_role);
      if(page_role == 'new'){
          form = useForm({
              name: null,
              email: null,
              password: null,
              password_confirmation: null,
          });
      } else if(page_role == 'edit'){
          form = useForm({
              name: $page.props.user.name,
              email: $page.props.user.email,
              password: null,
              password_confirmation: null,
          });
      }
      // console.log("$form: ", $form);
  }
  set_form();

  function submit(){
      let form_data = get_wrapped_form_data();
      console.log("form_data: ", form_data);
      if(page_role == "new"){
          form_data.post('/users');
      } else if(page_role == 'edit'){
          form_data.patch('/users');
      }
  }

  function get_wrapped_form_data(){
      if(page_role == 'edit'){
          if(!$form.password){
              $form['password'] = '';
              $form['password_confirmation'] = '';
          }
          return $form
          .transform( (data) => ({
              _method: "patch",
              user: {
                  ...data,
              },
              commit: "Save changes",
              id: $page.props.user.id
          }));
      } else {
          return $form
          .transform( (data) => ({
              user: {
                  ...data,
              }
          }));
      }
  }

  function handle_input(field){
      if($form.errors[field]){
          $form.errors[field] = null;
          switch(field){
              case 'name':
                has_name_error = false;
                break;
              case 'email':
                has_email_error = false;
                break;
              case 'password':
                has_password_error = false;
                break;
              case 'password_confirmation':
                has_password_confirmation_error = false;
                break;
          }
      }
  }

  $: {
      if($form.errors.name){
          has_name_error = true;
      }
      if($form.errors.email){
          has_email_error = true;
      }
      if($form.errors.password){
          has_password_error = true;
      }
      if($form.errors.password_confirmation){
          has_password_confirmation_error = true;
      }
  }

</script>

<form on:submit|preventDefault={submit} class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
  <div class="form-input-box mb-4">
    <label for="name" class="tw_label_styles">Name</label>
    <input
      type="text"
      name="name"
      id="name"
      class={`tw_input_styles ${ has_name_error ? 'border-red-500' : ''}`}
      bind:value={$form.name}
      on:input={() => handle_input('name')}
    >
    {#if has_name_error}
      <!-- {console.log($form.errors.name)} -->
      {#if $form.errors.name.length == 1}
        <div class="form-error errortext">name {$form.errors.name}</div>
      {:else}
        {#each $form.errors.name as e}
          <li class="form-error errortext">{e}</li>
        {/each}
      {/if}
    {/if}
  </div>
  <div class="form-input-box mb-4">
    <label for="email" class="tw_label_styles">Email</label>
    <input
      type="email"
      name="email"
      id="email"
      class={`tw_input_styles ${ has_email_error ? 'border-red-500' : ''}`}
      bind:value={$form.email}
      on:input={() => handle_input('email')}
    >
    {#if has_email_error}
      <!-- {console.log($form.errors.email)} -->
      {#if $form.errors.email.length == 1}
        <div class="form-error errortext">email {$form.errors.email}</div>
      {:else}
        {#each $form.errors.email as e}
          <li class="form-error errortext">{e}</li>
        {/each}
      {/if}
    {/if}
  </div>
  <InputPassword
    label={'Password'}
    bind:value={$form.password}
    errors={$form.errors}
    role={'new'}
    on:input={() => handle_input('password')}
    has_error={has_password_error}
  />
  <InputPassword
    label={'Confirm Password'}
    bind:value={$form.password_confirmation}
    errors={$form.errors}
    on:input={() => handle_input('password_confirmation')}
    has_error={has_password_confirmation_error}
  />
  <div class="flex items-center justify-between">
    <button type="submit" class="bluebtn" disabled={$form.processing}>Save changes</button>
  </div>
</form>
