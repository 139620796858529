<script>
  import { Link } from '@inertiajs/svelte';
  export let user;
  export let context;

  const stats = [
    { view: 'following', count: user.following_count },
    { view: 'followers', count: user.followers_count }
  ];
</script>

<div class="stats flex gap-x-4">
  {#each stats as { view, count }}
    {#if context !== view}
      <Link href="/users/{user.id}/{view}" class="bland-link">
        <strong id={view} class="stat">{count}</strong>
        {view}
      </Link>
    {:else}
      <div>
        <strong id={view} class="stat">{count}</strong>
        {view}
      </div>
    {/if}
  {/each}
</div>
