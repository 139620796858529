<script>
  import { inertia } from '@inertiajs/svelte';
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  export let event_msg;
  export let data;
  export let styles;

  function handle_click(e){
      e.preventDefault();
      // console.log("data: ", data);
      dispatch(event_msg, data);
  }
</script>

<a
  class={styles}
  on:click={handle_click}
  href="#"
  use:inertia="{{ preserveScroll: true }}"
  on:keypress={() => { return false }}
>
  <slot></slot>
</a>
