<script>
  import { createEventDispatcher } from 'svelte';
  import axios from 'axios';

  export let user_id;
  export let initial_is_following;
  export let initial_relationship_id;
  export let initial_follower_count;

  let is_following = initial_is_following;
  let relationship_id = initial_relationship_id;
  let follower_count = initial_follower_count;
  let is_loading = false;

  const dispatch = createEventDispatcher();

  async function toggle_follow(){
      if(is_loading) return;

      is_loading = true;

      try {
          if(is_following){
              const response = await axios.delete(`/relationships/${relationship_id}`);
              const { following, followers_count, relationship_id: new_relationship_id } = response.data;

              is_following = following;
              relationship_id = new_relationship_id;
              follower_count = followers_count;
          } else {
              const response = await axios.post('/relationships', { followed_id: user_id });
              const { following, followers_count, relationship_id: new_relationship_id } = response.data;

              is_following = following;
              relationship_id = new_relationship_id;
              follower_count = followers_count;
          }

          dispatch('follow_toggled', {
              user_id,
              is_following,
              relationship_id,
              follower_count
          });
      } catch(error){
          console.error('Error changing follow status:', error);
          // Handle error (e.g., show an error message to the user)
      } finally {
          is_loading = false;
      }
  }

</script>

<button 
  on:click={toggle_follow} 
  disabled={is_loading}
  class="follow-button {is_following ? 'following btn1' : 'not-following bluebtn'}"
>
  {is_following ? 'Following' : 'Follow'}
</button>

<style>
.follow-button {
  /* Add your button styles here */
}
.following {
  /* Styles for when following */
}
.not-following {
  /* Styles for when not following */
}
</style>
