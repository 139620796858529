import { mergeAttributes, Node } from '@tiptap/core';
import { SvelteNodeViewRenderer } from 'svelte-tiptap';
import ImageView from '@/components/rich_text_editor/image_view.svelte';

export const ImageNode = Node.create({
    name: 'imageNode',
    group: 'block',
    atom: true,
    draggable: true,
    addAttributes(){
        return {
            // ...Image.config.addAttributes(),
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            caption: {
                default: null,
            },
            allowCaption: {
                default: null,
            },
        }
    },
    parseHTML(){
        return [
            {
                tag: 'image-node',
            },
        ];
    },
    renderHTML( { HTMLAttributes } ){
        return [
            'image-node',
            mergeAttributes(
              HTMLAttributes,
              {
                  src: HTMLAttributes.src,
                  alt: HTMLAttributes.alt,
                  caption: HTMLAttributes.caption,
                  'allow-caption': HTMLAttributes.allowCaption,
              }
            )
        ];
    },
    addNodeView(){
        return SvelteNodeViewRenderer( ImageView );
    },
    toDOM(node){
        const { src, alt, caption, allowCaption } = node.attrs;
        const attrs = {
            src,
            alt,
            caption,
            'allow-caption': allowCaption,
        };
        return [ 'image-node', attrs ];
    },
});
