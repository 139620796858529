<script>
  import { createEventDispatcher, onDestroy } from 'svelte';
  const dispatch = createEventDispatcher();
  export let name;
  export let title;
  export let width;
  export let buttons;
  export let message;
  export let z_index;
  export let dialog;
  export let active = false;
  export let disabled = false;
  console.log("modal")

  export function show_modal(){
      dialog.showModal();
      active = true;
      dispatch('open');
  }

  function closef(cb){
    dialog.close();
    active = false;
    dispatch(cb);
  }

  function close(){
    dialog.close();
    active = false;
    dispatch('cancel');
  }

</script>

<dialog
  id={name}
  bind:this={dialog}
  class="rounded-lg shadow-xl overflow-hidden { z_index ? z_index : 'z-50'}"
  on:close
> <!-- sm:my-8 sm:align-middle sm:max-w-4xl {`sm:${width}`} -->
  <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div on:click={close} class="float-right cursor-pointer rounded-sm hover:bg-gray-200">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
    </div>
    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">
        {title}
      </h3>
      <div class="mt-2">
        {#if $$slots.body}
          <div>
            <slot name="body"></slot>
          </div>
        {:else if message}
          {message}
        {/if}
      </div>
    </div>
  </div>
  <div class="bg-gray-50 px-4 py-3 flex justify-evenly">
    {#if buttons}
      {#each buttons as button}
        <div class="ml-4">
          {#if button[2]}
            <button
              type="button"
              on:click={() => closef(button[2])}
              class="{button[1]}"
              disabled={disabled}
            >
              {button[0]}
            </button>
          {:else}
            <button
              type="button"
              on:click={close}
              class="{button[1]}"
            >
              {button[0]}
            </button>
          {/if}
        </div>
      {/each}
    {/if}
  </div>
</dialog>

<style>
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
</style>
