function format_time_ago(date_string) {
    const date = new Date(date_string);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
        return 'just now';
    } else if (minutes < 60) {
        return `${minutes}m ago`;
    } else if (hours < 24) {
        return `${hours}h ago`;
    } else if (days < 30) {
        return `${days}d ago`;
    } else if (months < 12) {
        return `${months}mo ago`;
    } else {
        return `${years}y ago`;
    }
}

function is_valid_json(str){
    try {
        JSON.parse(str);
        return true;
    } catch (error){
        return false;
    }
}

function parse_str_as_json(str){
    try {
        return JSON.parse(str);
    } catch(error){
        return str;
    }
}

function capitalize(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
    format_time_ago,
    is_valid_json,
    parse_str_as_json,
    capitalize
}
