<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
  export let mobile_layout;
</script>

<script>
  import { page } from '@inertiajs/svelte';
  import SignUpHome from '@/pages/page_partials/_sign_up_home.svelte';
  import UserHome from '@/pages/page_partials/_user_home.svelte';

  console.log("page: ", $page);
</script>

{#if $page.props.auth}
  <UserHome
    {mobile_layout}
  />
{:else}
  <SignUpHome
  />
{/if}
