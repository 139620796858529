<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import axios from 'axios';
  import { onMount, afterUpdate } from 'svelte';
  import { flip } from 'svelte/animate';
  import { fade, scale } from 'svelte/transition';
  import { page, router } from '@inertiajs/svelte';
  import {
      item_list, current_page, cached_pages,
      check_cache_resource, is_loading, is_error,
      load_more_items, all_items_loaded
  } from '@/helpers/lazy_loading.js';
  import PageTitle from '@/components/page_title.svelte';
  import Post from '@/pages/posts/_post.svelte';
  import Stats from '@/components/follower_stats.svelte';
  import Modal from '@/components/modal.svelte';
  export let flash;
  export let posts;
  let total_posts_count = $page.props.posts_metadata.n_posts;
  // console.log("$page.props: ", $page.props);
  let user = $page.props;
  let is_following = user?.is_following;
  let relationship_id = user?.relationship_id;
  const gravatar = $page.props.gravatar;
  const current_user = $page.props.auth.user;
  const current_user_id = current_user.id;
  // export let mobile_layout;
  let resource = 'posts';
  let x_post;

  $all_items_loaded = false;
  let items_per_page = 10;

  const initial_data = posts;
  $item_list = initial_data;
  
  if(!$cached_pages.has($current_page)){
      $cached_pages.set($current_page, { posts: initial_data });
  }
  
  $current_page += 1;

  $: {
      $item_list = posts
  };

  $: query_data = {
      user_id: user.id,
      last_created_at: $item_list.length > 0 ? $item_list.slice(-1)[0].created_at : -1
  };

  function should_load_more_items(){
      return !$all_items_loaded && !$is_loading && !$is_error;
  }

  const observer = new IntersectionObserver(entries => {
      if( !redirect_refresh && entries[0].isIntersecting && should_load_more_items() ){
          load_more_items( resource, '/next', items_per_page, query_data );
      }
  });

  let sentinel;
  onMount(() => {
      check_cache_resource(resource);

      if(!redirect_refresh){
          if(sentinel){
              observer.observe(sentinel);
          }
    
          if( initial_data.length == 0 ){
              $all_items_loaded = true;
          } else if( should_load_more_items() ){
              // Make an initial call to load_more_items
              load_more_items( resource, '/next', items_per_page, query_data );
          }
      }
  });

  afterUpdate(() => {
      // console.log("component updated!")
      if(redirect_refresh && refresh_page_data_complete){
          // observer.observe(sentinel);

          redirect_refresh = false;
          refresh_page_data_complete = false;
      }
  });

  function handle_retry(){
      if( should_load_more_items() ){
          load_more_items( resource, '/next', items_per_page, query_data );
      }
      else if( $all_items_loaded ){
          alert( "All posts have been loaded" );
      }
  }

  const delete_post_confirmation_modal = {
      name: 'delete_post_confirmation',
      title: 'Delete this post?',
      buttons: [
        [ 'Cancel', 'btn1', null ],
        [ 'Delete', 'btn dbtn', 'delete' ]
      ]
  };

  let delete_post_modal;
  function set_delete_post_confirmation_modal(x){
      x_post = $item_list.find( p => p.id == x.id );
      delete_post_modal.show_modal();
  }

  let redirect_refresh = false;
  let refresh_page_data_complete = false;
  function delete_post(){
      router.delete(`/posts/${x_post.id}`, {
          preserveScroll: true,
          onSuccess: () => {
              redirect_refresh = true;
              delete_post_from_cached_pages(x_post.id);
              transfer_cached_page_data();
          }
      });
  }

  function delete_post_from_cached_pages(post_id){
      for(const [ page_n, page_data ] of $cached_pages){
          const post_index = page_data.posts.findIndex( post => post.id == post_id );
          if(post_index !== -1){
              $cached_pages.get(page_n).posts.splice(post_index, 1);
              break;
          }
      }
  }

  function transfer_cached_page_data(){
      // console.log("posts: ", posts);
      $item_list = [];

      for(const page_data of $cached_pages.values()){
          $item_list = [ ...$item_list, ...page_data.posts ];
      }

      refresh_page_data_complete = true;
  }

  let follow_refresh = 0;
  let follow_process_active = false;
  async function toggle_follow(){
      if(follow_process_active) return;
      follow_process_active = true;

      try {
          let response;
          if(is_following){
              response = await axios.delete(`/relationships/${relationship_id}`);
          } else {
              response = await axios.post('/relationships', { followed_id: user.id })
          }

          const data = response.data;

          console.log("Response data: ", data);
          is_following = data.following;
          relationship_id = data.relationship_id;
          user.followers_count = data.followers_count;
          follow_refresh++;
      } catch(error){
          console.error("Error setting follow state:", error);
          // Handle error
      } finally {
          follow_process_active = false;
      }
  }

</script>

<div class="user-show-container">
    <div class="user-show-header my-5 pt-2.5 flex justify-between">
        <div class="user-left-container">
          <div class="user-container flex gap-x-4 mb-5">
              <div class="gravatar-box">
                <img class="gravatar rounded-full" alt={user.name} src={gravatar}>
              </div>
              <div class="user-details">
                <div class="user-main-detail-container mb-2">
                  <h1 class="user-name-heading text-3xl font-bold mt-3">{user.name}</h1>
                  <p class="text-gray-500 text-sm">email: {user.email}</p>
                </div>
              </div>
          </div>
          <div class="user-follower-stats-container">
            {#key follow_refresh}
              <Stats
                {user}
              />
            {/key}
          </div>
        </div>
        <div class="user-right-container flex flex-col items-center">
          <div class="user-controls-container mb-5">
            {#if current_user && current_user_id !== user.id}
              <div class="follow-button-container">
                {#key follow_refresh}
                  <button
                    type="button"
                    class={ is_following ? 'btn1' : 'bluebtn' }
                    on:click={toggle_follow}
                  >
                    { is_following ? 'Following' : 'Follow' }
                  </button>
                {/key}
              </div>
            {/if}
          </div>
          <div class="user-metadata-container">
            <h3>Posts { total_posts_count }</h3>
          </div>
        </div>
    </div>
    {#if posts.length}
      <div class="user-posts-container">
        <ul class="post-list-container">
          <!-- NB: usage of the key post.id in the below each block is necessary -->
          <!-- with the animate to make the entire action of the delete operation responsive -->
          {#each $item_list as post (post.id)}
              <li
                class="animated-li"
                animate:flip={{ duration: 200 }}
                in:scale|local={{ duration: 200, delay: 200 }}
                out:fade|local={{ duration: 200 }}
              >
                <Post
                  {post}
                  {user}
                  current_user={current_user_id}
                  on:delete={(e) => set_delete_post_confirmation_modal(e.detail)}
                />
              </li>
          {/each}
          <div bind:this={sentinel}></div>
      
          {#if $is_loading}
              <div class="loading-indicator">Loading...</div>
          {/if}
      
          {#if $is_error}
              <div class="error-message">
                  Failed to fetch posts. Please <button on:click={handle_retry}>Retry</button>.
              </div>
          {/if}
        </ul>
      </div>
    {/if}
</div>
<Modal
  {...delete_post_confirmation_modal}
  bind:this={delete_post_modal}
  on:delete={() => delete_post()}
>
  <div slot=body>
    <p>This action cannot be undone.</p>
  </div>
</Modal>
