<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import PageTitle from '@/components/page_title.svelte';
  import Form from "./_form.svelte";
</script>

<PageTitle>Sign up</PageTitle>
<div class="form-container w-full max-w-xs m-auto">
  <Form/>
  <p class="text-center text-gray-500 text-xs">
    &copy;2023 Lentobox All rights reserved.
  </p>
</div>
