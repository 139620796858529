<script>
  import { inertia, page } from '@inertiajs/svelte';
  import { onMount } from 'svelte';
  import HMenu from './hmenu.svelte';
  $: user = $page.props.auth && $page.props.auth.user ? $page.props.auth.user : null;

  // console.log("nav page props: ", $page.props);
  
  let dropdown_menu;
  let dropdown_active = false;
  let activation_click = true;
  function toggle_dropdown(e){
      dropdown_active = !dropdown_active;
      // console.log("dropdown_active: ", dropdown_active ? 'yes' : 'no')
      if(dropdown_active){
          activation_click = true;
          window.addEventListener('click', handle_click_outside_dropdown_menu);
      }
  }

  function handle_click_outside_dropdown_menu(e){
      if(activation_click){
          activation_click = false;
          return;
      }
      const menu_dimensions = dropdown_menu.getBoundingClientRect();
      if(
          e.clientX < menu_dimensions.left ||
          e.clientX > menu_dimensions.right ||
          e.clientY < menu_dimensions.top ||
          e.clientY > menu_dimensions.bottom
      ){
          dropdown_active = false;
          window.removeEventListener('click', handle_click_outside_dropdown_menu);
      }
  }

  function handle_click_outside_dropdown_menu_x(e){
      if(!dropdown_active) return;
      const dropdown_menu = document.querySelector('.dropdown-menu');
      if (dropdown_menu && !dropdown_menu.contains(e.target) && e.target.id !== "menu-button"){
          dropdown_active = false;
          // console.log("dropdown_active: ", dropdown_active ? 'yes' : 'no')
          window.removeEventListener('click', handle_click_outside_dropdown_menu);
      }
  }

  onMount(() => {
      // window.addEventListener('click', handle_click_outside_dropdown_menu);
      handle_window_resize(); // Call the function to set the initial layout based on the window width
      window.addEventListener('resize', handle_window_resize);
      return () => {
          window.removeEventListener('resize', handle_window_resize);
      }
  });

  let hmenu_active = false;
  function handle_window_resize(){
      hmenu_active = window.innerWidth <= 800;
  }

</script>

<div class="nav-container self-center">
  <!-- The HMenu ("Hamburger Menu") component is only used on mobile -->
  {#if hmenu_active}
      <HMenu
        {user}
      />
  {:else}
      <!-- Nav Bar -->
      <nav class="navbar">
        <ul class="flex">
          <li class="mr-6">
            <a href="/" use:inertia>Home</a>
          </li>
          <li class="mr-6">
            <a href="/help" use:inertia>Help</a>
          </li>
          {#if user}
              <!-- href="/users" -->
              <li class="mr-6">
                <a href="/users" use:inertia>Users</a>
              </li>
              <li class="mr-5">
                <div class="relative inline-block text-left">
                  <div>
                    <button
                      type="button"
                      class="inline-flex w-full justify-center gap-x-1.5 bg-cyan-950 text-cyan-200 underline hover:text-cyan-300"
                      id="menu-button"
                      aria-expanded="true"
                      aria-haspopup="true"
                      on:click={(e) => toggle_dropdown(e)}
                    > <!-- rounded-md text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 -->
                      Account
                      <svg
                        class="text-cyan-200 hover:text-cyan-300"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        viewBox="0 -960 960 960"
                        width="20"
                      >
                        <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/>
                      </svg>
                    </button>
                  </div>
                    {#if dropdown_active}
                      <!--
                      Dropdown menu, show/hide based on menu state.
        
                      Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    -->
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <div
                      id="std-dropdown-menu"
                      class="dropdown-menu absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-cyan-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1"
                      bind:this={dropdown_menu}
                    >
                      <div class="py-1" role="none">
                        <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                        <a href="/users/{user.id}" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0" use:inertia>Profile</a>
                        <a href="/users/{user.id}/edit" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1" use:inertia>Settings</a>
                      </div>
                      <div class="py-1" role="none">
                        <a href="/logout" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2" use:inertia="{ {  method: 'delete' } }">Log out</a>
                      </div>
                    </div>
                  {/if}
                </div>
              </li>
          {:else}
              <li class="mr-6">
                <a href="/login" use:inertia>Log in</a>
              </li>
          {/if}
        </ul>
      </nav>
  {/if}
</div>
