<script>
  import { Link } from '@inertiajs/svelte';
  export let user;
  export let gravatar;
  export let posts_data;

</script>

<div class="user-info-home-container my-5 pt-2.5 flex items-center gap-x-4">
  <div class="gravatar-box mt-3">
    <Link
      href="/users/{user.id}"
      class="no-underline hover:underline"
    >
      <img class="gravatar rounded-full" alt={user.name} src={gravatar}>
    </Link>
  </div>
  <div class="user-details">
    <h1 class="user-name-heading text-3xl font-bold mt-3">{user.name}</h1>
    <div>
      <Link
        href="/users/{user.id}"
        class="no-underline hover:underline mt-0 text-sm"
      >
        view profile
      </Link>
    </div>
    {#key posts_data.n_posts}
      <div class="text-sm">
        {`${posts_data.n_posts} ${posts_data.pluralized_posts_str}`}
      </div>
    {/key}
  </div>
</div>


