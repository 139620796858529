<script>
  import { inertia } from '@inertiajs/svelte';
  import PageTitle from '@/components/page_title.svelte';
</script>

<PageTitle>Welcome to Lentoverse</PageTitle>

<h2>
  This is the home page for the
  <a href="https://www.railstutorial.org/">Ruby on Rails Tutorial</a>
  sample application.
</h2>
<div class="signup-btn-container my-3">
  <!-- {{ href: '/signup', method: 'post' }} -->
  <button class="bluebtn" use:inertia="{{ href: '/signup', method: 'get' }}" type="button">Sign up</button>
  <!-- <a href="/about" use:inertia>About</a> -->
</div>
<div class="rails-link-container my-3 w-fit">
  <a href="https://rubyonrails.org/" target="_blank" id="rails-link">
    <img src="/images/rails.svg" alt="Rails logo" width="200px">
  </a>
</div> 
<p class="text-xs">Find me in app/javascript/pages/home.svelte</p>
