import { writable, get } from 'svelte/store';

const all_items_loaded = writable(false);
const item_list = writable([]);
const current_page = writable(1);
const is_loading = writable(false);
const is_error = writable(false);

const cached_pages = writable(new Map());
const reset_cached_pages = () => {
    console.log("resetting cached pages");
    cached_pages.set( new Map() );
}
const check_cache_resource = (resource) => {
    if(get(cached_pages).size > 0){
        const sample_v = get(cached_pages).values().next().value;

        if( !sample_v.hasOwnProperty(resource) ){
            reset_cached_pages();
        }
    }
}


async function fetch_items(resource, base_url, page, items_per_page, query_data = {}) {
    try {
        // is caching safe in all cases?
        // if a user's posts are frequently updated, will the cache_pages index overload the client?
        // if the page's resources are significant, you'll need to add code to offset how much data gets loaded into the cache
        if (get(cached_pages).has(page)) {
            return get(cached_pages).get(page);
        }

        const query_params = new URLSearchParams({
            resource: resource,
            page: page.toString(),
            per_page: items_per_page.toString(),
            ...query_data
        });
    
        const url = `${base_url}?${query_params.toString()}`;
        
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch ${resource}.`);
        }
        
        const data = await response.json();
        // cached_pages.set(page, data);
        cached_pages.update(($store) => {
            $store.set(page, data);
            return $store;
        });

        const items = data[resource];
        const has_more = data.has_more;

        return { items, has_more};
    } catch (error) {
        throw error;
    }
}

async function load_more_items(resource, base_url, items_per_page, query_data){
    if( get(all_items_loaded) || get(is_loading) || get(is_error) ){
        // Don't proceed if all items are loaded, currently loading, or there was an error
        return;
    }

    is_loading.set(true);
    is_error.set(false);
    try {
        const { items, has_more } = await fetch_items(resource, base_url, get(current_page), items_per_page, query_data);
        item_list.update(current_items => [...current_items, ...items]);
        current_page.update(page => page + 1);
        all_items_loaded.set(!has_more);
    } finally {
        is_loading.set(false);
    }
}

export {
    all_items_loaded,
    item_list,
    current_page,
    cached_pages,
    is_loading,
    is_error,
    reset_cached_pages,
    check_cache_resource,
    load_more_items
}
