<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import PageTitle from '@/components/page_title.svelte';
</script>

<PageTitle>Help</PageTitle>
<p>
  Get help on the Ruby on Rails Tutorial at the
  <a href="https://www.railstutorial.org/help">Rails Tutorial Help page</a>.
  To get help on this sample app, see the
  <a href="https://www.railstutorial.org/book"><em>Ruby on Rails Tutorial</em>
  book</a>.
</p>
<br>
<p class="text-xs">Find me in app/javascript/pages/help.svelte</p>
