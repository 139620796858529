import { mergeAttributes, Node } from '@tiptap/core';
// import { SvelteNodeViewRenderer } from 'svelte-tiptap';
// import MediaView from '@/components/rich_text_editor/media_view.svelte';

export const MediaNode = Node.create({
    name: 'mediaNode',
    group: 'block',
    atom: true,
    addAttributes(){
        return {
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            caption: {
                default: null,
            },
            user: {
                default: null,
            }
        }
    },
    parseHTML(){
        return [
            {
                tag: 'media-node',
            },
        ];
    },
    renderHTML( { node, HTMLAttributes } ){
        // console.log('node: ', node)
        // console.log('HTMLAttributes: ', HTMLAttributes);
        /* 
          IN THE FUTURE:
            This code can be made more generic so that it reads whatever media resource
            it receives for a type ('image', 'video', etc) and then renders the supporting markup
            conditionally.
        */

        const { src, alt, caption, user } = node.attrs;

        if(src && src.startsWith('data:')){
            const [ media_type, base64_data ] = src.split(',');
            const [ type, subtype ] = media_type.slice(5).split(';'); // cuts "data:" from media_type

            if(type.startsWith('image/') && caption){
                // return a figure element having class media-node to render an image
                return [
                    'div',
                    {
                        class: 'media-node',
                    },
                    [
                        'figure',
                        {
                            class: 'w-full h-full flex flex-col items-center', // hover:cursor-pointer
                            title: `Image from ${user} - ${caption}`,
                        },
                        [
                            'img',
                            {
                                src: `data:${type}${subtype ? `;${subtype}` : ''};base64,${base64_data}`,
                                alt: `Image from ${user} - ${caption}`,
                                class: 'mb-2',
                            }
                        ],
                        [
                            'figcaption',
                            {
                                class: 'media-node-caption text-center text-xs',
                            },
                            caption
                        ],
                    ]
                ];
            } else if(type.startsWith('image/') && !caption){
                // return a figure element having class media-node to render an image
                return [
                  'div',
                  {
                      class: 'media-node',
                  },
                  [
                      'figure',
                      {
                          class: 'w-full h-full flex flex-col items-center', // hover:cursor-pointer
                          title: `Image from ${user}`,
                      },
                      [
                          'img',
                          {
                              src: `data:${type}${subtype ? `;${subtype}` : ''};base64,${base64_data}`,
                              alt: `Image from ${user}`,
                              class: 'mb-2',
                          }
                      ],
                  ]
              ];
            }
        }

        // Fallback rendering for other types of media or when src is not in the expected format
        return [
            'div',
            {
                ...HTMLAttributes,
            },
            'Unsupported media type',
        ];
    },
});
