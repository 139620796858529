<script>
  import { page, router } from '@inertiajs/svelte';
  import { cached_pages, item_list } from '@/helpers/lazy_loading.js';
  import UserInfo from '@/components/user_info.svelte';
  import Stats from '@/components/follower_stats.svelte';
  import Post from '@/pages/posts/_form.svelte';
  import HomeFeed from '@/components/user_home/user_home_feed.svelte';
  import Modal from '@/components/modal.svelte';
  export let mobile_layout;
  let x_post;

  let is_mobile_layout;
  $: {
    if (mobile_layout === undefined) {
      is_mobile_layout = window.innerWidth <= 800;
    } else {
      is_mobile_layout = mobile_layout;
    }
  }

  let props = $page.props;
  let current_user = props.auth.user;
  let gravatar = props.gravatar
  let posts_data = props.posts_metadata;
  let new_post = props.new_post;
  let feed = props.feed;

  function refresh(x){
      if(x == 'use_cache'){
          delete_post_from_cached_pages(x_post.id);
          transfer_cached_page_data();
      }
      feed = $page.props.feed;
      posts_data = $page.props.posts_metadata;
  }

  // let delete_post_confirmation_modal_active = false;
  const delete_post_confirmation_modal = {
      name: 'delete_post_confirmation',
      title: 'Delete this post?',
      buttons: [
        [ 'Cancel', 'btn1', null ],
        [ 'Delete', 'btn dbtn', 'delete' ]
      ]
  };

  let delete_post_modal;
  function set_delete_post_confirmation_modal(post){
      x_post = post;
      delete_post_modal.show_modal();
  }

  let redirect_refresh = false;
  let refresh_page_data_complete = false;
  function delete_post(){
      router.delete(`/posts/${x_post.id}`, {
          preserveScroll: true,
          onSuccess: () => {
              redirect_refresh = true;
              refresh('use_cache');
          }
      }); // , data, options
  }

  function delete_post_from_cached_pages(post_id){
      for(const [ page_n, page_data ] of $cached_pages){
          const post_index = page_data.posts.findIndex( post => post.id == post_id );
          if(post_index !== -1){
              $cached_pages.get(page_n).posts.splice(post_index, 1);
              break;
          }
      }
  }

  function transfer_cached_page_data(){
      // console.log("feed: ", feed);
      $item_list = [];

      for(const page_data of $cached_pages.values()){
          $item_list = [ ...$item_list, ...page_data.posts ];
      }

      refresh_page_data_complete = true;
  }

  function handle_refresh_complete(){
      redirect_refresh = false;
      refresh_page_data_complete = false;
  }

</script>

{#key is_mobile_layout}
  {#if is_mobile_layout}
    <div class="user-home-container mx-2">
      <div class="user-home-mobile-top-container mb-6">
        <div class="user-info-container mb-4">
          <UserInfo
            user={current_user}
            {gravatar}
            {posts_data}
          />
          <div class="user-follower-stats-container">
            <Stats
              user={current_user}
              context={'home'}
            />
          </div>
        </div>
        <div class="user-post-create-container">
          <Post
            {new_post}
            on:submit={refresh}
          />
        </div>
      </div>
      <div class="user-feed-container">
        <HomeFeed
          {current_user}
          posts={feed}
          {redirect_refresh}
          {refresh_page_data_complete}
          on:delete={(e) => set_delete_post_confirmation_modal(e.detail)}
          on:refresh_complete={handle_refresh_complete}
        />
      </div>
    </div>
  {:else}
    <div class="user-home-container mx-2 flex">
      <div class="user-home-left-container w-2/3 mr-4">
        <div class="user-info-container mb-4">
          <UserInfo
            user={current_user}
            {gravatar}
            {posts_data}
          />
          <div class="user-follower-stats-container">
            <Stats
              user={current_user}
              context={'home'}
            />
          </div>
        </div>
        <div class="user-post-create-container">
          <Post
            {new_post}
            on:submit={refresh}
          />
        </div>
      </div>
      <div class="user-feed-container w-2/3">
        <HomeFeed
          {current_user}
          posts={feed}
          {redirect_refresh}
          {refresh_page_data_complete}
          on:delete={(e) => set_delete_post_confirmation_modal(e.detail)}
          on:refresh_complete={handle_refresh_complete}
        />
      </div>
    </div>
  {/if}
{/key}
<Modal
  {...delete_post_confirmation_modal}
  bind:this={delete_post_modal}
  on:delete={() => delete_post()}
>
  <div slot=body>
    <p>This action cannot be undone.</p>
  </div>
</Modal>
