// `editor` is a ProseMirror editor object
function get_node_at_cursor(editor){
    const { view } = editor;
    const { anchorNode } = view.dom.ownerDocument.getSelection();

    if(!anchorNode){
        return null;
    }

    return anchorNode.nodeType === Node.TEXT_NODE ? anchorNode.parentNode : anchorNode;
}

function get_node_info(node){
    if(!node){
        return null;
    }

    const node_info = {
        type: node.nodeType === Node.ELEMENT_NODE ? node.nodeName.toLowerCase() : 'text',
        text: node.textContent,
    };

    // if node is type 'link'
    if(node_info.type === 'a'){
        node_info.href = node.getAttribute('href');
        node_info.target = node.getAttribute('target');
        node_info.rel = node.getAttribute('rel');
    }

    // add other cases for extracting node attribute data as needed

    return node_info;
}

export {
    get_node_at_cursor,
    get_node_info,
}
