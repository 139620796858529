<script>
  import { onMount } from 'svelte';
  import Header from '@/components/header.svelte';
  import FlashMessages from "@/components/flash_messages.svelte";
  import Footer from "@/components/footer.svelte";
  export let page_title = false;
  let base_title = 'Lentoverse';
  let mobile_layout = false;

  onMount(() => {
      handle_window_resize(); // Call the function to set the initial layout based on the window width
      window.addEventListener('resize', handle_window_resize);
      return () => {
          window.removeEventListener('resize', handle_window_resize);
      }
  });

  function handle_window_resize(){
      mobile_layout = window.innerWidth <= 800;
      console.log("mobile layout?", mobile_layout ? "yes" : "no");
  }
</script>

<svelte:head>
  <title>{ page_title ? `${page_title} | ${base_title}` : base_title }</title>
</svelte:head>

{#key mobile_layout}
  <div class="bg-white mb-4">
    <main>
      <Header />
      <div id="main-container" class="body-container max-w-7xl mx-auto pt-16 px-4 sm:px-8">
        <!-- try to style FlashMessages so they're absolutely positioned -->
        <!-- make them more discrete - they don't need to be full width -->
        <FlashMessages />
        <slot
          mobile_layout={mobile_layout}
        />
      </div>
      <Footer
        {mobile_layout}
      />
    </main>
  </div>
{/key}
