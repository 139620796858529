const tw_label_styles = "block text-gray-700 text-sm font-bold mb-2";
const tw_input_styles = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
const tw_pw_input_styles = "shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline";
const bluebtn = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
const errortext = "text-red-500 text-xs italic";

export {
    tw_label_styles,
    tw_input_styles,
    tw_pw_input_styles,
    bluebtn,
    errortext
}
