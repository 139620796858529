import axios from 'axios';
import { createInertiaApp } from '@inertiajs/svelte';
import { meta_content } from '@/utils/meta_content';

// import all svelte components under /pages
const pages = import.meta.glob('../pages/**/*.svelte', { eager: true });

// replaced by InertiaCsrf.rb
axios.defaults.headers.common['X-CSRF-Token'] = meta_content('csrf-token');

// document.addEventListener('DOMContentLoaded', () => {
//     const app = document.getElementById('app');
//     const is_using_inertia = Boolean(app) // && app.getAttribute('data-page');
//     if(is_using_inertia){
//         init_inertia();
//     }
// });

// function init_inertia(){}
createInertiaApp({
  resolve: (name) => pages[`../pages/${name}.svelte`],

  setup({ el, App, props }) {
    const _app = new App({ target: el, props });
  },
});
