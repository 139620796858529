<script>
  import { useForm } from '@inertiajs/svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  const dispatch = createEventDispatcher();
  import RichTextEditor from '@/components/rich_text_editor.svelte' 
  export let new_post;
  export let flash;

  console.log("new_post: ", new_post);

  let form;
  // let input = '';
  let content = '';
  let has_error = false;
  let is_submit_disabled = true;

  function set_form(){
      form = useForm( { content: new_post.content } );
  }
  set_form();

  let submit_refresh = 0;
  function submit(){
      let form_data = get_wrapped_form_data();
      // console.log("form_data.content:", JSON.stringify(JSON.parse(form_data.content), null, 2));
      // console.log("calling posts/create")
      // return
      form_data.post('/posts', {
          onSuccess: () => {
              // input = '';
              content = '';
              set_form();
              dispatch('submit');
              submit_refresh++;
          },
          onError: (errors) => {
              console.log(`Form submission errors: ${errors}`)
              // console.log("$form.errors", $form.errors)
          }
      });
  }

  function get_wrapped_form_data(){
      return $form
      .transform( (data) => ({
          post: {
              ...data,
          }
      }));
  }

  function handle_input(){
      // $form.content = input;
      if($form.errors.content){
          $form.errors.content = null;
          has_error = false;
      }
  }

  // Use of Tiptap's placeholder means $form.content is never truly empty, so we use this boolean expression
  // to set the submit button's disabled state
  $: is_content_empty = $form.content === null || $form.content === undefined || $form.content === '{"type":"doc","content":[{"type":"paragraph"}]}';
  $: is_submit_disabled = is_content_empty || $form.processing;

  $: {
      if($form.errors && Object.values($form.errors).length > 0){
          has_error = true;
      } else {
          has_error = false;
      }
  }
</script>

<form on:submit|preventDefault={submit} class="mx-2 my-8">
  <div class="form-input-box mb-4">
    <label
      for="post"
      class=tw_label_styles
    >
      Post
    </label>
    {#if $form.errors}
      <div class="PostErrorBox my-3">
        {#each Object.entries($form.errors) as [ key, value ]}
          <div class="form-error errortext mt-3">
            {value}
          </div>
        {/each}
      </div>
    {/if}
    <!-- <input
      type="textarea"
      name="post"
      id="post"
      class={`tw_input_styles ${ has_error ? 'border-red-500' : '' }`}
      bind:value={input}
      on:input={handle_input}
      placeholder="Compose a new post..."
    > -->
    {#key submit_refresh}
      <RichTextEditor
        bind:content={$form.content}
      />
    {/key}
    <!-- <input
      type="hidden"
      name="content_json"
      bind:value={$form.content_json}
    > -->
  </div>
  <div class="form-post-submit-container flex items-center justify-between">
    <!-- <button type="submit" disabled={$form.processing}>Save Draft</button> -->
    <button type="submit" class=bluebtn disabled={ is_submit_disabled }>Post</button>
  </div>
</form>
