<script>
  // import { router } from '@inertiajs/svelte';
  import { onMount, afterUpdate, createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  import { flip } from 'svelte/animate';
  import { fade, scale } from 'svelte/transition';
  import {
      item_list, current_page, cached_pages,
      check_cache_resource, is_loading, is_error,
      load_more_items, all_items_loaded
  } from '@/helpers/lazy_loading.js';
  import Post from '@/pages/posts/_post.svelte';
  export let current_user;
  export let posts;
  export let redirect_refresh;
  export let refresh_page_data_complete;
  let resource = 'posts';

  $all_items_loaded = false;
  let items_per_page = 10;

  const initial_data = posts;
  $item_list = initial_data;

  if(!$cached_pages.has($current_page)){
      $cached_pages.set($current_page, { posts: initial_data });
  }
  
  $current_page += 1;

  $: {
      $item_list = posts;
      // console.log('reactive posts: ', posts);
  };

  $: query_data = {
      user_id: current_user.id,
      last_created_at: $item_list.length > 0 ? $item_list.slice(-1)[0].created_at : -1
  };

  function should_load_more_items(){
      return !$all_items_loaded && !$is_loading && !$is_error;
  }

  const observer = new IntersectionObserver(entries => {
      if( !redirect_refresh && entries[0].isIntersecting && should_load_more_items() ){
          query_data.last_created_at = $item_list.length > 0 ? $item_list.slice(-1)[0].created_at : -1;
          load_more_items( resource, '/next', items_per_page, query_data );
      }
  });

  let sentinel;
  onMount(() => {
      // console.log("component mounted!");
      check_cache_resource(resource);

      // console.log("feed mount");
      if(!redirect_refresh){
          if(sentinel){
              observer.observe(sentinel);
          }
    
          if( initial_data.length == 0 ){
              $all_items_loaded = true;
          } else if( should_load_more_items() ){
              // Make an initial call to load_more_items
              load_more_items( resource, '/next', items_per_page, query_data );
          }
      }
  });

  afterUpdate(() => {
      // console.log("component updated!");
      if(redirect_refresh && refresh_page_data_complete){
          // observer.observe(sentinel);

          redirect_refresh = false;
          dispatch('refresh_complete');
      }
  });

  function handle_retry(){
      if( should_load_more_items() ){
          load_more_items( resource, '/next', items_per_page, query_data );
      }
      else if( $all_items_loaded ){
          alert( "All posts have been loaded" );
      }
  }

</script>

{#if $item_list.length}
  <ul class="feed-container">
    <!-- in the future, the feed may be more than just posts, so instead of each item set as a 'post', may be 'feed_item'-->
    {#each $item_list as post (post.id)}
      <li
        class="animated-li"
        animate:flip={{ duration: 200 }}
        in:scale|local={{ duration: 200, delay: 200 }}
        out:fade|local={{ duration: 200 }}
      >
        <Post
          {post}
          user={current_user}
          {current_user}
          on:delete
        />
      </li>
    {/each}
    <div bind:this={sentinel}></div>

    {#if $is_loading}
        <div class="loading-indicator">Loading...</div>
    {/if}

    {#if $is_error}
        <div class="error-message">
            Failed to fetch posts. Please <button on:click={handle_retry}>Retry</button>.
        </div>
    {/if}
  </ul>
{/if}
