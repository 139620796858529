<script>
  // import { onMount, onDestroy } from "svelte";
  import { createEventDispatcher } from 'svelte'; 
  const dispatch = createEventDispatcher();
  export let editor;

  const bbl_menu_btn = 'hover:bg-gray-200 rounded-full'
  
  let href;
  $: if(editor && editor.isActive('link')){
      href = editor.getAttributes('link').href;
      show_menu();
  } else {
      href = null;
      hide_menu();
  }

  let bbl_menu;
  function show_menu(){
      // console.log('show menu')
      if(bbl_menu){
          bbl_menu.style.visibility = 'visible';
      }
  }
  function hide_menu(){
      // console.log('hide menu')
      if(bbl_menu){
          bbl_menu.style.visibility = 'hidden';
      }
  }

  function edit_link(){
      dispatch('set');
  }

  function unset_link(){
      editor.chain().focus().unsetLink().run();
  }
</script>

<div class="bubble-menu" bind:this={bbl_menu}> <!-- { active && href ? 'block' : 'hidden' } -->
  <!-- {active && href ? 'visible' : 'invisible'} -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div class="link-display">
    <a href={href} target="_blank">{href}</a>
  </div>
  <button class="{bbl_menu_btn} mx-2" on:click={edit_link}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#000"
    >
    <!-- fill="#e8eaed" -->
      <path
        d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"
      />
    </svg>
  </button>
  <button class="{bbl_menu_btn}" on:click={unset_link}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#000"
    >
    <!-- fill="#e8eaed" -->
      <path
        d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
      />
    </svg>
  </button>
</div>

<style>
  .bubble-menu {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 4px;
  }

  .link-display {
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
  }
</style>
