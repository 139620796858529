<script>
  // import { onMount, afterUpdate } from 'svelte';
  import { NodeViewWrapper } from 'svelte-tiptap';
  import { is_valid_image_dimensions } from '@/helpers/image_helper.js';
  export let node;
  export let updateAttributes;
  export let selected;
  export let editor;
  let img_el;
  // onMount(() => {
  //     console.log("ImageNode mounted")
  //     console.log("node attrs: ", node.attrs);
  //     console.log("image element: ", img_el);
  //     console.log("editor: ", editor);
  // });

  // afterUpdate(() => {
  //     console.log("ImageNode updated")
  //     console.log("node attrs: ", node.attrs)
  //     console.log("selected?: ", selected ? 'yes' : 'no')
  //     console.log("image element: ", img_el);
  // });

  const { src, alt, caption, allowCaption } = node.attrs;

  let img_natural_width = 0;
  let img_natural_height = 0;
  let img_width = 0;
  let img_height = 0;
  function handle_image_load(){
      // console.log("Image loaded")
      if(!is_valid_image_dimensions(img_el.naturalWidth, img_el.naturalHeight)){
          window.alert("Your images need to be less than 5000 pixels in height and width.");
          // Remove the invalid image node from the editor
          handle_delete();
      } else {
          img_natural_width = img_el.naturalWidth;
          img_natural_height = img_el.naturalHeight;

          const limit_width = 500; // default width of the rich text editor is 700

          if(img_natural_width > limit_width){
              const scale_factor = limit_width / img_natural_width;
              img_width = limit_width;
              img_height = Math.round( img_natural_height * scale_factor );
          } else {
              img_width = img_natural_width;
              img_height = img_natural_height;
          }
      }
  }

  function handle_delete(){
      // Get the current position of the image node
      const pos = editor.view.posAtDOM( img_el );
      const tr = editor.state.tr;
      tr.deleteRange( pos, pos + node.nodeSize );
      editor.view.dispatch( tr );
  }


  let captionv = caption || '';
  function handle_caption_blur( e ){
      update_caption(e);
  }

  function handle_caption_keydown(e){
      if(e.key === 'Enter'){
          e.preventDefault();
          update_caption(e);
          editor.view.focus();
      }
  }

  function update_caption(e){
      const new_caption = e.target.value;
      captionv = new_caption;
      updateAttributes( { caption: new_caption } );
  }

  const textarea_overwrite_styles = "height: 20px; padding: 0 !important; --tw-ring-inset: none; --tw-ring-offset-width: 0px; --tw-ring-offset-color: transparent; --tw-ring-color: transparent; --tw-ring-offset-shadow: none; --tw-ring-shadow: none;"

</script>

<NodeViewWrapper
    as="image-node"
    data-drag-handle
    contenteditable="false"
    class="whitespace-normal flex flex-col relative rounded-lg"
    style="width: {img_width}px;"
    allow-caption={ allowCaption ? '' : null }
    caption={ captionv ? captionv : null }
> <!-- ProseMirror-selectednode -->
    <button
        on:click={handle_delete}
        class="absolute top-2 right-2 z-10 p-1 bg-gray-800 opacity-60 hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-white rounded-full flex items-center justify-center inline-flex"
        type="button"
    >
        <span class="flex items-center justify-center">
            <span class="flex">
                <svg
                    fill="#fff"
                    height="24px"
                    icon-name="delete"
                    viewBox="0 -960 960 960"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                > <!-- fill="#e8eaed" -->
                    <path
                        d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                    />
                </svg>
            </span>
        </span>
    </button>
    <div class="contain-paint box-border rounded-lg w-full h-full { selected ? "ring ring-blue-500" : "hover:ring-2 hover:ring-blue-500" }"> <!-- shadow-[0_0_0_4px_#e2e8f0] -->
        <img
            bind:this={img_el}
            {src}
            alt={ captionv ? captionv : alt }
            on:load={handle_image_load}
            class="box-border max-w-full max-h-[600px] min-w-[20px] min-h-[20px] block"
            style="max-height: max(20px, {img_height}px); max-width: max(20px, min(100%, {img_width}px));"
        />
    </div>
    {#if allowCaption}
        <div class="flex flex-col items-center mt-2">
            <input type="text" class="absolute -left-[99999px]" />
            <textarea
                type="text"
                rows="1"
                class="font-sans text-xs border-none text-center resize-none w-full box-border rounded-lg outline-none focus:outline-none"
                placeholder="Add a caption"
                style={ textarea_overwrite_styles }
                value={ captionv }
                on:blur={ handle_caption_blur }
                on:keydown={ handle_caption_keydown }
            ></textarea>
        </div>
    {/if}
</NodeViewWrapper>
