<script>
  import { page } from '@inertiajs/svelte';

  console.log("$page: ", page);
  export let errors;
</script>

<div class="error-explanation-container">
  <div class="alert alert-danger">
    The form contains X errors
  </div>
  <ul>
    {#each errors as error}
      <li>msg</li>
    {/each}
  </ul>
</div>
