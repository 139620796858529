<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import { onMount, afterUpdate } from 'svelte';
  import { page as page_props, router } from '@inertiajs/svelte';
  import { item_list, current_page, cached_pages, check_cache_resource, is_loading, is_error, load_more_items, all_items_loaded } from '@/helpers/lazy_loading.js';
  import PageTitle from '@/components/page_title.svelte';
  import User from '@/components/index_user.svelte';
  import Modal from '@/components/modal.svelte';
  // import { btn, dbtn } from '../../ buttons';
  export let users;
  // export let mobile_layout;
  let resource = 'users';
  let x_user;

  let current_user = $page_props.props.auth.user;
  function is_admin(user){
      return user.admin;
  }

  $all_items_loaded = false;
  let items_per_page = 10;

  const initial_data = users;
  if(!$cached_pages.has($current_page)){
      $cached_pages.set($current_page, { users: initial_data });
  }

  $current_page += 1;

  $: {
      $item_list = users;
      // console.log("$item_list:", $item_list)
  };

  $: query_data = {
      last_user_id: $item_list.length > 0 ? $item_list.slice(-1)[0].id : -1
  };

  function should_load_more_items(){
      return !$all_items_loaded && !$is_loading && !$is_error;
  }

  const observer = new IntersectionObserver(entries => {
      if( !redirect_refresh && entries[0].isIntersecting && should_load_more_items() ){
          load_more_items( resource, '/next', items_per_page, query_data );
      }
  });

  let sentinel;
  onMount(() => {
      // console.log('component mounted!');
      check_cache_resource(resource);

      if(!redirect_refresh){
          if(sentinel){
              observer.observe(sentinel);
          }
    
          if( initial_data.length == 0 ){
              $all_items_loaded = true;
          } else if( should_load_more_items() ){
              // Make an initial call to load_more_items
              load_more_items( resource, '/next', items_per_page, query_data );
          }
      }
  });

  afterUpdate(() => {
      // console.log("component updated!")
      if(redirect_refresh && refresh_page_data_complete){
          // observer.observe(sentinel);

          redirect_refresh = false;
          refresh_page_data_complete = false;
      }
  });

  function handle_retry(){
      if( should_load_more_items() ){
          load_more_items( resource, '/next', items_per_page, query_data );
      }
      else if( $all_items_loaded ){
          alert( "All users have been loaded" );
      }
  }

  const delete_user_confirmation_modal = {
      name: 'delete_user_confirmation',
      title: 'Delete user',
      // width: 'w-2/3',
      z_index: 'z-50',
      buttons: [
        [ 'Yes', 'btn dbtn', 'delete' ],
        [ 'No', 'btn1', null ]
      ]
  };

  let delete_user_modal;
  function set_delete_user_confirmation_modal(user){
      // console.log("user: ", user);
      x_user = user;
      delete_user_modal.show_modal();
  }

  let redirect_refresh = false;
  let refresh_page_data_complete = false;
  function delete_user(){
      router.delete(`/users/${x_user.id}`, {
          preserveScroll: true,
          onSuccess: () => {
              redirect_refresh = true;
              delete_user_from_cached_pages(x_user.id);
              transfer_cached_page_data();
          }
      });
  }

  function delete_user_from_cached_pages(user_id){
      for(const [ page_n, page_data ] of $cached_pages){
          const user_index = page_data.users.findIndex( user => user.id == user_id );
          if(user_index !== -1){
              $cached_pages.get(page_n).users.splice(user_index, 1);
              break;
          }
      }
  }

  function transfer_cached_page_data(){
      // console.log("users: ", users);
      $item_list = [];

      for(const page_data of $cached_pages.values()){
          $item_list = [ ...$item_list, ...page_data.users ];
      }

      refresh_page_data_complete = true;
  }

</script>

<PageTitle>All users</PageTitle>
<div class="users-container">
  {#if $item_list.length}
    {#each $item_list as user}
        <User
          {user}
          {current_user}
          admin={is_admin(current_user)}
          on:delete={(e) => set_delete_user_confirmation_modal(e.detail)}
        />
    {/each}
    <div bind:this={sentinel}></div>

    {#if $is_loading}
        <div class="loading-indicator">Loading...</div>
    {/if}

    {#if $is_error}
        <div class="error-message">
            Failed to fetch users. Please <button on:click={handle_retry}>Retry</button>.
        </div>
    {/if}
  {/if}
</div>
<Modal
  bind:this={delete_user_modal}
  {...delete_user_confirmation_modal}
  on:delete={() => delete_user()}
>
  <div slot=body>
    <p class="mb-2">{`Deleting user ${x_user?.id}: ${x_user?.name}`}</p>
    <p>This action cannot be undone.</p>
  </div>
</Modal>
